import { useState, useEffect, useCallback, useRef } from 'react';
import type { ReactElement, ReactNode } from 'react';
import Script from 'next/script';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import type { MasterLayout as MasterLayoutProps } from '../../../../amplienceTypes/schemas/imported/master-layout-schema';
import { colors, media } from '../styles';
import { TopNavigation } from '../../header/TopNavigation';
import { CookieWallSettings } from '../../cookie/CookieWallSettings';
import { CookieNotice } from '../../cookie/CookieNotice';
import { useAppContext } from '../../../../utilities/context/static/AppContext';
import { UserProvider } from '../../../../utilities/context/dynamic/UserContext';
import { debounce, parseLocale } from '../../../../utilities/parsers';
import {
  parseSmartAppBanner,
  smartAppBannerId,
  smartAppBannerCloseId,
} from '../../../../utilities/constants/smartBanner';
import { COOKIE_NOTICE } from '../../../../utilities/constants/localStorageKeys';
import { SideNavigation } from '../../header/sideBar/SideNavigation';
import { FooterNavigation } from '../../footer/FooterNavigation';
import { AudioEye } from '../../vendors/AudioEye';
import { GTMHeadScript } from '../../vendors/GTM';
import { HumanHeadScript } from '../../vendors/Human';
import { SmartServeScript, UVScript } from '../../vendors/Qubit';
import { HeaderUSP } from '../../headerUSP/HeaderUSP';
import { Breadcrumbs } from '../../../pdp/productDetail/productDetailControl/Breadcrumbs';
import { AccessibilityLabels } from '../../accessibilityLabels/AccessibilityLabels';
import { CartProvider } from '../../../../utilities/context/dynamic/CartContext';
import { WishlistProvider } from '../../../../utilities/context/dynamic/WishlistContext';
import { useGalleryContext } from '../../../../utilities/context/dynamic/GalleryContext';
import { useNavActionContext } from '../../../../utilities/context/dynamic/NavActionContext';
import { useLayoutDesignContext } from '../../../../utilities/context/dynamic/LayoutDesignContext';
import { Exponea } from '../../vendors/Exponea';
import type { GlobalSFSCChat } from '../../../../amplienceTypes/schemas/imported/global-sfsc-chat-schema';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { storageAvailable } from '../../../../utilities/helpers';
import { SmartBannerProvider } from '../../../../utilities/context/dynamic/SmartBannerContext';
import { NavigationType } from '../../../../utilities/graphql/codegen';
import { useAlgoliaInsights } from '../../vendors/useAlgoliaInsights';
import type { Title as UspList } from '../../../../amplienceTypes/schemas/exported/usp-list-schema';
import { MobileCategory } from '../../../homepage/MobileCategory';
import { useCookieContext } from '../../../../utilities/context/dynamic/CookieContext';
import { parsePageInfoDataLayer, parseUserInfoDataLayer } from '../../../../utilities/vendors';
import { Forter } from '../../vendors/Forter';
import { useDataLayerContext } from '../../../../utilities/context/static/DataLayerContext';
import type { PageProperties } from '../../../../amplienceTypes/schemas/imported/generic-layout-slot-schema';
import { Usabilla } from '../../vendors/Usabilla';
import { HideInHighstreetApp } from '../HideInHighstreetApp';
import { AppliedVoucherNotification } from '../../../plp/globalNotification/AppliedVoucherNotification';
import { DiscontinuedProductNotification } from '../../../plp/globalNotification/DiscontinuedProductNotification';
import { useProductListContext } from '../../../../utilities/context/static/ProductListContext';
import { ExponeaPushNotificationsPopup } from '../../../../utilities/vendors/exponea/ExponeaPushNotificationsPopup';
import { smartAppBannerCloseIcon } from '../icons/plain/smartBannerCloseIcon';
import { PageTypes } from '../../../../utilities/constants';
import { NewsletterOverlay } from '../../newsletter/NewsletterOverlay';

const SfscChat = dynamic<GlobalSFSCChat>(
  () => import('../../sfscChat/SfscChat').then(mod => mod.SfscChat),
  {
    ssr: false,
  }
);

const ExitIntentBannerModal = dynamic(
  async () => import('../../../shared/exitIntentBanner/ExitIntentBannerModal'),
  {
    ssr: false,
  }
);

gsap.registerPlugin(ScrollToPlugin);

const style = {
  SideNavOpenAnimationMain: css<{ $sideNavOpen: boolean; $hasFlyoutNavigation: boolean }>`
    transition-timing-function: ease-in-out;
    transition-duration: 0.25s;
    transition-property: left, width, transform;
    will-change: left, width;
    left: ${({ $sideNavOpen, $hasFlyoutNavigation }) =>
      $sideNavOpen && !$hasFlyoutNavigation ? 'var(--side-nav-width)' : 0};

    @media ${media.greaterThan('sm')} {
      transform: none;
    }

    @media ${media.greaterThan('xl')} {
      width: ${({ $sideNavOpen, $hasFlyoutNavigation }) =>
        $sideNavOpen && !$hasFlyoutNavigation ? 'calc(100% - var(--side-nav-width))' : '100%'};
      transition-timing-function: ease-in-out;

      ${({ $hasFlyoutNavigation }) =>
        !$hasFlyoutNavigation &&
        css`
          .has-open-sideNavigation & {
            left: var(--side-nav-width);
          }
        `}
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
};

const S = {
  Container: styled.div<{
    $sideNavOpen: boolean;
    $galleryZoomIn: boolean;
    $hasStickyProductImageGallery?: boolean;
    $pageType: PageTypes;
  }>`
    display: flex;
    flex-direction: column;
    position: relative;
    height: ${({ $hasStickyProductImageGallery }) =>
      $hasStickyProductImageGallery ? 'initial' : '100%'};
    max-height: ${({ $galleryZoomIn }) => ($galleryZoomIn ? '100vh' : 'fit-content')};

    ${({ $pageType, $hasStickyProductImageGallery }) =>
      [PageTypes.PRODUCT].includes($pageType) &&
      css`
        overflow: ${$hasStickyProductImageGallery ? 'initial' : 'hidden'};

        @media ${media.greaterThan('xl')} {
          overflow: initial;
        }
      `}

    @media ${media.greaterThan('lg')} {
      max-height: fit-content;
    }
  `,

  SideNavOverlay: styled.div<{ $sideNavOpen: boolean; $hasFlyoutNavigation: boolean }>`
    display: block;
    opacity: ${({ $sideNavOpen }) => ($sideNavOpen ? 0.5 : 0)};
    background: ${colors.BLACK};
    z-index: 502;
    position: fixed;
    inset: 0;
    visibility: ${({ $sideNavOpen }) => ($sideNavOpen ? 'visible' : 'hidden')};
    transition: visibility 0s linear 0s, opacity 0.25s ease-in 0.25s;

    @media ${media.between('lg', 'xl')} {
      opacity: ${({ $sideNavOpen, $hasFlyoutNavigation }) =>
        $sideNavOpen && !$hasFlyoutNavigation ? 0.5 : 0};
    }

    @media ${media.greaterThan('xl')} {
      display: none;
    }
  `,

  SideNav: styled.nav<{
    $sideNavOpen: boolean;
    $smartBannerLoaded: boolean;
    $smartBannerHeight: number;
    $hasFlyoutNavigation: boolean;
  }>`
    position: fixed;
    inset: 0;
    margin-top: ${({ $smartBannerLoaded, $smartBannerHeight }) =>
      $smartBannerLoaded && $smartBannerHeight
        ? `calc(var(--top-nav-height) + ${$smartBannerHeight}px)`
        : 'var(--top-nav-height)'};
    transform: translate3d(${({ $sideNavOpen }) => ($sideNavOpen ? 0 : '-100%')}, 0, 0);
    visibility: ${({ $sideNavOpen, $hasFlyoutNavigation }) =>
      $sideNavOpen && !$hasFlyoutNavigation ? 'visible' : 'hidden'};
    z-index: 503;
    max-width: var(--side-nav-width);
    transition: transform 0.25s ease-in-out, visibility 0.25s ease-in-out;

    @media ${media.greaterThan('sm')} {
      width: 250px;
      max-width: none;
    }

    @media ${media.greaterThan('xl')} {
      right: auto;
      background: ${colors.WHITE};

      ${({ $hasFlyoutNavigation }) =>
        !$hasFlyoutNavigation &&
        css`
          .has-open-sideNavigation & {
            transform: translate3d(0, 0, 0);
            visibility: visible;
          }
        `}
    }

    @media ${media.lessThan('lg')} {
      visibility: ${({ $sideNavOpen }) => ($sideNavOpen ? 'visible' : 'hidden')};
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,

  Main: styled.main<{
    $sideNavOpen: boolean;
    $smartBannerLoaded: boolean;
    $smartBannerHeight: number;
    $hasFlyoutNavigation: boolean;
    $hasStickyProductImageGallery?: boolean;
  }>`
    ${style.SideNavOpenAnimationMain}
    box-sizing: border-box;
    position: relative;
    width: 100%;
    background-color: ${({ $hasStickyProductImageGallery }) =>
      $hasStickyProductImageGallery ? colors.WHITE : 'initial'};
    padding-top: ${({ $smartBannerLoaded, $smartBannerHeight }) =>
      $smartBannerLoaded && $smartBannerHeight
        ? `calc(var(--top-nav-height) + ${$smartBannerHeight}px)`
        : 'var(--top-nav-height)'};
    z-index: 501;

    @media ${media.greaterThan('sm')} {
      padding-top: var(--top-nav-height);
    }

    @media ${media.greaterThan('xl')} {
      ${({ $hasFlyoutNavigation }) =>
        !$hasFlyoutNavigation &&
        css`
          .has-open-sideNavigation & {
            width: calc(100vw - var(--side-nav-width) - var(--scrollbar-width));
          }
        `}
    }

    .is-highstreet & {
      --top-nav-height: 0px;
    }
  `,

  Footer: styled.footer<{ $sideNavOpen: boolean; $hasFlyoutNavigation: boolean }>`
    ${style.SideNavOpenAnimationMain}
    position: relative;
    background-color: ${colors.WHITE_SMOKE_GREY};
    z-index: 500;

    @media ${media.greaterThan('xl')} {
      ${({ $hasFlyoutNavigation }) =>
        !$hasFlyoutNavigation &&
        css`
          .has-open-sideNavigation & {
            width: calc(100vw - var(--side-nav-width) - var(--scrollbar-width));
          }
        `}
    }

    .is-highstreet & {
      display: none;
    }
  `,

  BackToTop: styled.button<{ $backToTop: boolean }>`
    position: fixed;
    visibility: hidden;

    @media ${media.greaterThan('sm')} {
      background: ${colors.WHITE};
      border: none;
      bottom: 0;
      right: 0;
      padding: 0 26px;
      color: ${colors.ACCESSIBILITY_GREY};
      line-height: 36px;
      font-weight: 700;
      text-align: center;
      transition: opacity 0.2s linear, visibility 0.2s linear;
      text-decoration: none;
      cursor: pointer;
      min-width: 130px;
      outline: 0;
      box-sizing: content-box;
      z-index: 505;
      visibility: ${({ $backToTop }) => ($backToTop ? 'visible' : 'hidden')};
      opacity: ${({ $backToTop }) => ($backToTop ? 1 : 0)};

      &:hover,
      &:focus {
        color: ${colors.NERO_GREY};

        g {
          fill: ${colors.NERO_GREY};
        }
      }

      &:active {
        color: ${colors.NERO_GREY};
        background-color: transparent;

        g {
          fill: ${colors.NERO_GREY};
        }
      }

      &::before {
        font-family: gstar-icons;
        content: '\\F112';
        font-size: 8px;
        line-height: inherit;
        position: absolute;
        right: 10px;
        width: 1em;
        margin: 0 0.2em;
      }
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
};

export type OpenedModal = 'locale-selector' | 'message-box-notification' | null;
export interface HeaderOpenModalProps {
  openedModal: OpenedModal;
  setOpenedModal: (val: OpenedModal) => void;
}

interface LayoutProps {
  pageProperties?: PageProperties;
  main: ReactNode;
  pageType: PageTypes;
  uspListContent?: UspList;
}

export function MasterLayout({
  main,
  pageType,

  // Data From Amplience
  topNavigation,
  footerNavigation,
  cookieWallSettings,
  cookieNotice,
  backToTopBtn,
  loginLabel,
  messageBoxNotification,
  accessibilityLabels,
  sfscChat,
  uspListContent,
  pageProperties,
  exponeaPopup,
  newsletterOverlay,
}: LayoutProps & MasterLayoutProps): ReactElement {
  const router = useRouter();
  const { t } = useTranslation('common', { keyPrefix: 'masterLayout' });

  const [showCookieWall, setShowCookieWall] = useState(false);
  const [showCookieNotice, setShowCookieNotice] = useState(false);
  const [backToTop, setBackToTop] = useState(false);
  const [smartBannerLoaded, setSmartBannerLoaded] = useState(false);
  const [smartBannerLink, setSmartBannerLink] = useState('');
  const [smartBannerHeight, setSmartBannerHeight] = useState(0);
  const [openedModal, setOpenedModal] = useState<OpenedModal>(null);
  const { country, locale, language, pushToContentSquare, pushToAppSettings } = useAppContext();
  const { pushToDataLayer } = useDataLayerContext();
  const { cocoCookie, setCocoCookie } = useCookieContext();
  const { galleryZoomIn } = useGalleryContext();
  const { sideNavOpened, setFlyoutItem, closeSideNav } = useNavActionContext();
  const { hasStickyProductImageGallery } = useLayoutDesignContext();
  const {
    configuration: {
      enableCookieWall,
      enableUniversalLinks,
      enablePDPBreadcrumbs,
      appStoreName,
      enableQubit,
      enableExponea,
      enableHuman,
      qubitEventsEnabled,
      navigationType,
      klarnaHighOnlineReturnsSegment,
      slice,
      defaultCurrency,
      isBodyOnlyView,
      isLineSession,
    },
    countryEn,
  } = useStaticContext();
  const { isPLP } = useProductListContext();

  const cocoFullConsent = cocoCookie === '11111';
  const cocoMinimumConsent =
    cocoCookie && typeof cocoCookie === 'string' && cocoCookie.slice(-3) === '111';
  const { initialize: initAlgoliaInsights } = useAlgoliaInsights();

  const usabillaButton = useRef<HTMLImageElement | null>();

  const showBackToTop = () => {
    if (window.scrollY > 400) {
      setBackToTop(true);
    } else {
      setBackToTop(false);
    }
  };

  const scrollToTop = () => {
    gsap.to(window, {
      duration: 0.5,
      scrollTo: { y: 0, autoKill: true },
      onComplete: () => {
        const hamburger = document.getElementById('hamburger-menu');

        hamburger?.setAttribute('tabindex', '0');
        hamburger?.focus();
      },
    });
  };

  const closeCookieNotice = () => {
    if (storageAvailable('localStorage')) {
      localStorage.setItem(COOKIE_NOTICE, 'true');
    }

    setShowCookieNotice(false);
  };

  const onCloseCookieWall = (coco: string) => {
    setShowCookieWall(false);
    setCocoCookie(coco);
  };

  const handleOriginalLocationEvent = useCallback(() => {
    if (storageAvailable('sessionStorage')) {
      const storage = window.sessionStorage;
      const originalLocation = storage.getItem('originalLocation') || document.location.href;

      storage.setItem('originalLocation', originalLocation);
      pushToDataLayer(
        {
          event: 'originalLocation',
          originalLocation,
        },
        true
      );
    }
  }, [pushToDataLayer]);

  const handleSPAEvents = useCallback(async () => {
    if (country && language && locale && countryEn) {
      const pageInfo = parsePageInfoDataLayer(
        locale,
        language,
        slice,
        defaultCurrency,
        countryEn,
        pageType
      );
      const userInfo = await parseUserInfoDataLayer(locale);

      pushToDataLayer({ event: 'spa_page_load', page: pageInfo, user: userInfo }, true);
    }
  }, [country, language, locale, countryEn, slice, defaultCurrency, pageType, pushToDataLayer]);

  useEffect(() => {
    handleOriginalLocationEvent();
    handleSPAEvents();
  }, [handleOriginalLocationEvent, handleSPAEvents]);

  useEffect(() => {
    const debouncedDetectScroll = debounce(showBackToTop, 100);

    window.addEventListener('scroll', debouncedDetectScroll);

    return () => window.removeEventListener('scroll', debouncedDetectScroll);
  }, []);

  useEffect(() => {
    if (enableCookieWall) {
      setShowCookieWall(!cocoCookie);
      setShowCookieNotice(false);
    } else {
      setShowCookieWall(false);
      setShowCookieNotice(
        storageAvailable('localStorage') ? !localStorage.getItem(COOKIE_NOTICE) : true
      );
    }

    const smartBanner =
      enableUniversalLinks && (!!cocoCookie || !enableCookieWall)
        ? parseSmartAppBanner(appStoreName || '', `${language}_${country.toUpperCase()}`)
        : '';

    setSmartBannerLink(smartBanner);
  }, [language, country, cocoCookie, enableCookieWall, enableUniversalLinks, appStoreName]);

  useEffect(() => {
    const removePathsInSessionStorage = () => {
      if (storageAvailable('sessionStorage')) {
        const storage = window.sessionStorage;

        storage.removeItem('originalLocation');
      }
    };

    window.addEventListener('beforeunload', removePathsInSessionStorage);

    return () => {
      window.removeEventListener('beforeunload', removePathsInSessionStorage);
    };
  }, []);

  useEffect(() => {
    const bodyEl = document.querySelector('body');
    const onSmartBannerAppUpdated = (
      mutationList: MutationRecord[],
      observer: MutationObserver
    ) => {
      const smartBannerApp = document.getElementById(smartAppBannerId);

      if (
        smartBannerApp &&
        smartBannerApp.style.display !== 'none' &&
        smartBannerApp.style.height !== 'auto'
      ) {
        setSmartBannerLoaded(true);
        setSmartBannerHeight(smartBannerApp.offsetHeight);

        const closeBtn = document.getElementById(smartAppBannerCloseId);

        if (smartBannerLoaded && closeBtn) {
          observer.disconnect();
          closeBtn.addEventListener('click', () => setSmartBannerLoaded(false));
          closeBtn.innerHTML = smartAppBannerCloseIcon;
        }
      }
    };

    const mutationObserver = new MutationObserver(onSmartBannerAppUpdated);

    if (bodyEl) {
      mutationObserver.observe(bodyEl, { attributes: true, childList: true, subtree: true });
    }

    return () => mutationObserver.disconnect();
  }, [smartBannerLoaded]);

  const usabillaButtonFirstClick = useCallback(() => {
    pushToContentSquare('trackPageEvent', 'Action | Feedback button clicked');
  }, [pushToContentSquare]);

  const whenButtonIsReady = (callback: (elm: HTMLIFrameElement) => void) => {
    const observer = new MutationObserver((mutations, obs) => {
      const iframe = document.querySelector(
        '.usabilla_live_button_container iframe'
      ) as HTMLIFrameElement;

      if (iframe) {
        obs.disconnect();
        callback(iframe);
      }
    });

    observer.observe(document, {
      childList: true,
      subtree: true,
    });
  };

  useEffect(() => {
    whenButtonIsReady(elm => {
      const iFrameDoc = elm?.contentWindow?.document;

      if (iFrameDoc) {
        usabillaButton.current = iFrameDoc.querySelector('img');
        usabillaButton.current?.addEventListener('click', usabillaButtonFirstClick, {
          once: true,
        });
      }
    });

    return () => {
      if (usabillaButton.current) {
        usabillaButton.current.removeEventListener('click', usabillaButtonFirstClick);
      }
    };
  }, [usabillaButtonFirstClick]);

  useEffect(() => {
    if (!enableCookieWall || cocoMinimumConsent) {
      initAlgoliaInsights();
    }
  }, [cocoMinimumConsent, initAlgoliaInsights, enableCookieWall]);

  useEffect(() => {
    window.ampliencePageProperties = pageProperties;
  }, [pageProperties]);

  useEffect(() => {
    const { languageIso } = parseLocale(locale);

    pushToAppSettings({
      enableCookieWall,
      consentCookieValue: cocoCookie || '00000',
      isBodyOnlyView,
      registration: { isLineSession },
      languageIso,
      language,
      country,
      klarnaSegments: klarnaHighOnlineReturnsSegment || '',
    });
  }, [
    cocoCookie,
    country,
    enableCookieWall,
    isBodyOnlyView,
    isLineSession,
    klarnaHighOnlineReturnsSegment,
    language,
    locale,
    pushToAppSettings,
    router.query.q,
  ]);

  const acceptedCookie = !enableCookieWall || cocoFullConsent;

  return (
    <UserProvider>
      {enableHuman && <HumanHeadScript />}
      <GTMHeadScript />
      {acceptedCookie && (
        <>
          {qubitEventsEnabled && <UVScript pageType={pageType} />}
          {enableQubit && <SmartServeScript />}
        </>
      )}
      <HideInHighstreetApp>
        {pageProperties?.smartAppBannerEnabled !== false && acceptedCookie && smartBannerLink && (
          <Script
            src={smartBannerLink}
            strategy="afterInteractive"
            onError={() => setSmartBannerLoaded(false)}
          />
        )}
      </HideInHighstreetApp>
      <AudioEye />
      <Forter />
      {(!enableCookieWall || cocoFullConsent) && enableExponea && <Exponea />}
      {enableExponea && exponeaPopup?.content && (
        <ExponeaPushNotificationsPopup {...exponeaPopup.content} />
      )}
      {!pageProperties?.excludeNewletterOverlayPageview && newsletterOverlay?.content && (
        <NewsletterOverlay {...newsletterOverlay.content} />
      )}
      {pageProperties?.liveChatEnabled !== false && acceptedCookie && sfscChat?.content && (
        <SfscChat {...sfscChat.content} />
      )}
      <S.Container
        suppressHydrationWarning
        $sideNavOpen={sideNavOpened}
        $galleryZoomIn={galleryZoomIn}
        $hasStickyProductImageGallery={hasStickyProductImageGallery}
        $pageType={pageType}
      >
        {accessibilityLabels?.content && <AccessibilityLabels {...accessibilityLabels.content} />}
        <CartProvider>
          <WishlistProvider>
            <HideInHighstreetApp>
              <ExitIntentBannerModal />
              {topNavigation?.content && (
                <TopNavigation
                  showCookieWall={showCookieWall}
                  smartBannerLoaded={smartBannerLoaded}
                  smartBannerHeight={smartBannerHeight}
                  loginLabel={loginLabel || t('loginLabel')}
                  messageBoxNotification={messageBoxNotification}
                  openedModal={openedModal}
                  setOpenedModal={setOpenedModal}
                  {...topNavigation.content}
                />
              )}
              <S.SideNavOverlay
                suppressHydrationWarning
                $sideNavOpen={sideNavOpened}
                onClick={closeSideNav}
                $hasFlyoutNavigation={
                  navigationType === NavigationType.HorizontalMainNavigationFlyOut
                }
              />
              <S.SideNav
                suppressHydrationWarning
                $sideNavOpen={sideNavOpened}
                id="sideNav"
                $smartBannerLoaded={smartBannerLoaded}
                $smartBannerHeight={smartBannerHeight}
                $hasFlyoutNavigation={
                  navigationType === NavigationType.HorizontalMainNavigationFlyOut
                }
              >
                <SideNavigation
                  loginLabel={loginLabel || t('loginLabel')}
                  messageBoxNotification={messageBoxNotification}
                  openedModal={openedModal}
                  setOpenedModal={setOpenedModal}
                />
              </S.SideNav>
            </HideInHighstreetApp>
            <S.Main
              suppressHydrationWarning
              $sideNavOpen={sideNavOpened}
              $smartBannerLoaded={smartBannerLoaded}
              $smartBannerHeight={smartBannerHeight}
              id="container"
              tabIndex={-1}
              onClick={() => setFlyoutItem('')}
              $hasFlyoutNavigation={
                navigationType === NavigationType.HorizontalMainNavigationFlyOut
              }
              $hasStickyProductImageGallery={hasStickyProductImageGallery}
            >
              {uspListContent && <HeaderUSP {...uspListContent} />}
              {enablePDPBreadcrumbs && pageType === PageTypes.PRODUCT && <Breadcrumbs />}
              <MobileCategory />
              <SmartBannerProvider value={{ smartBannerLoaded, smartBannerHeight }}>
                <AppliedVoucherNotification />
                {isPLP && <DiscontinuedProductNotification />}
                {main}
              </SmartBannerProvider>
            </S.Main>
          </WishlistProvider>
        </CartProvider>

        <HideInHighstreetApp>
          {pageProperties?.displayFooter !== false && (
            <S.Footer
              suppressHydrationWarning
              $sideNavOpen={sideNavOpened}
              onClick={() => setFlyoutItem('')}
              $hasFlyoutNavigation={
                navigationType === NavigationType.HorizontalMainNavigationFlyOut
              }
            >
              {footerNavigation?.content && (
                <FooterNavigation
                  {...footerNavigation.content}
                  amplienceShowAppStoreLinks={pageProperties?.displayAppStoreLinks}
                />
              )}
            </S.Footer>
          )}
        </HideInHighstreetApp>

        <S.BackToTop
          data-testid="button-back-to-top"
          $backToTop={backToTop}
          type="button"
          onClick={scrollToTop}
        >
          {backToTopBtn || t('backToTopBtn')}
        </S.BackToTop>

        <HideInHighstreetApp>
          {showCookieWall && cookieWallSettings?.content && (
            <CookieWallSettings close={onCloseCookieWall} {...cookieWallSettings.content} />
          )}

          {cookieNotice?.content && (
            <CookieNotice
              showCookieNotice={showCookieNotice}
              close={closeCookieNotice}
              {...cookieNotice.content}
            />
          )}
        </HideInHighstreetApp>
      </S.Container>
      {pageProperties?.feedbackButtonEnabled !== false && <Usabilla locale={locale} />}
    </UserProvider>
  );
}
